import React from 'react'

const ContactSection = () => {
  return (
<div className='mt-5 mx-20 pb-7 '>
    <h1 className='text-[54px] ibm-plex-serif-light mb-5 '>To Know More</h1>
       <div className='flex flex-col gap-2 ibm-plex-sans-mxied text-[22px]'>
        <h3>Have questions or ready to start? Contact us today!
        </h3>
        <h3>
        contact@media365.click</h3>
       </div>

</div>
  )
}

export default ContactSection