import React from 'react'
import arrow from "../../../assets/images/—Pngtree—white direction arrow right icon_4539096.png"
import gameimg from "../../../assets/images/Collage (3).png"
const AboutSection = ({setActiveMenu}) => {
  const handleScrollToSection = (targetId) => {
    const section = document.getElementById(targetId);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        setActiveMenu(targetId); 
    }
};

  
  return (
    <div className='text-center'>
    <section id='home'>
    <img src={gameimg} alt="game" className='w-full h-auto object-cover mt-[85px]' />
    </section>

    
      <section id='about-us' >
      <h3 className='font-light text-[54px] py-8 ibm-plex-serif-light'>Meet Media365</h3>
      
      <div className='flex flex-col px-[100px]'>
        <h6 className='ibm-plex-sans-normal text-[22px] leading-[42px]'>
          At Media365, we are passionate about creating engaging and immersive mobile games that captivate players around the world. Dedicated to developing games that combine creativity, innovation, and fun. Whether you're looking for a casual gaming experience or a deep, story-driven adventure, Media365 delivers high-quality games that resonate with players of all ages.
        </h6>
        <h6 className='ibm-plex-sans-normal text-[22px] leading-[42px]'>
          Our mission is to push the boundaries of mobile gaming by exploring new concepts and technologies, ensuring that every game we create offers something unique and exciting. We believe in the power of games to entertain, challenge, and inspire, and we strive to bring those experiences to life in every title we develop.
        </h6>
      </div>
      
      <div className='flex justify-center mt-[32px]'>
        <button className='flex items-center justify-center gap-2 bg-[#1174C1] text-white w-[156px] h-[51px]'  onClick={() => handleScrollToSection("contact")}>
          <span>Get In Touch</span>
          <img src={arrow} alt="arrow" className='mt-[4px] w-[17px]'/>
        </button>
      </div>
      
      <hr className='bg-[#777485] h-[1px] mx-20 mt-[80px]' />
      </section>

    </div>
  )
}

export default AboutSection
